/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Trustpilot(props) {
    return (
        <div
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="5419b757fa0340045cd0c938"
            data-businessunit-id="61b90fe7d136c62d8cb4ecc1"
            data-style-height="25px"
            data-style-width="100%"
        >
            <a
                href="https://www.trustpilot.com/review/divorce.com"
                target="_blank"
                rel="noopener"
            >
                Read more reviews on Trustpilot
            </a>
        </div>
    )
}
